<template>
    <div class="mt-2" id="faq">
        <h2 class="faq-title text-left mb-2">3NDK CHI SOU2AL ?</h2>
        <div class="py-2">
            <FaqItem v-for="(faqItem, i) in faqList" :key="i" :faqItem="faqItem" />
        </div>

    </div>
</template>

<script>
    import FaqItem from './FaqItem.vue';
    export default {
        name: "FaqList",
        components:{
            FaqItem
        },
        data(){
            return {
                faqList:[
                    {
                        question: "Dois-je être un client du CIH pour participer avec mon équipe ?",
                        answer: "L’inscription au tournoi est exclusivement réservée aux clients du CIH Bank. Si vous n’êtes pas encore client du CIH, nous vous encourageons à découvrir les avantages de la banque et de ses services en visitant le lien suivant. Découvrez les avantages des cartes CIH Code 30 et CIH Sayidati adaptées aux joueurs."
                    },
                    {
                       question: "Puis-je ouvrir un compte CIH en ligne sans me déplacer à la banque ?",
                       answer: "Tout à fait ! Vous pouvez ouvrir un compte CIH en ligne sans vous rendre à la banque, grâce à E-CIHBank. Cette plateforme vous permet d’ouvrir votre compte bancaire depuis le confort de votre domicile en seulement 5 étapes simples, en utilisant uniquement votre selfie ;)."
                    },
                    {
                       question: "Comment puis-je créer un compte sur Lgame.gg ?",
                       answer:`L’inscription des équipes au tournoi se fait en cinq étapes :<br>
                       1 - S’assurer que tous les membres de l’équipe ont un compte CIH.<br>
                       2 - Chaque membre de l’équipe doit créer un compte sur le site web, en suivant les instructions fournies.<br>3 - Le capitaine de l’équipe doit ensuite créer l’équipe sur le site web, en suivant la procédure décrite.<br>
                       4 - Le capitaine de l’équipe procède à l’inscription de l’équipe au tournoi.<br>
                       5 - Enfin, il faut rejoindre le serveur Discord du tournoi.`
                    },
                    {
                       question: "Où se déroulera la finale du tournoi ?",
                       answer: "Les qualifications se dérouleront en ligne et la grande finale aura lieu à Casablabnca le 18 mai."
                    },
                ]
            }
        }
    }
</script>

<style lang="css" scoped>
    .faq-title{
        color: var(--main-color);
        font-family: "monument_extendedultrabold" !important;
        font-weight: 600;
        position: relative;
        z-index: 2;
    }
    .faq-title::after{
        content:"/////////////////////////";
        font-family: "Roboto";
        font-size:18px;
        color: white;
        position: absolute;
        z-index: 1;
        position:absolute;
        bottom:-16px;
        left: 0;
    }
    @media screen and (max-width: 768px) {
        .faq-title {
            font-size: 18pt;
        }
    }
</style>