<template>
    <div>
        <script type="application/javascript" defer src="https://player.twitch.tv/js/embed/v1.js"
            ref="twitchScript"></script>

        <!-- Lgame NavBar -->
        <div>
            <LgameNavBar></LgameNavBar>
        </div>

        <div class="main-page-wrapper">
            <div class="main-page-landing-area d-flex flex-column justify-content-center align-items-center position-relative pt-3 pt-lg-4 pb-5 w-100 h-100"
                :style="{
                    backgroundImage: 'url('+require('@/assets/hero-bg.webp')+')',
                }">
                <div
                    class="landing-content d-flex flex-column justify-content-center align-items-center align-items-md-start w-100 container px-0">
                    <div class="row m-0 p-0">
                        <div class="col m-0 p-0">
                            <div
                                class="logos-container d-flex justify-content-center justify-content-md-start align-items-center px-2 px-lg-4 w-100">
                                <div class="logo-container tournament-logo">
                                    <img :src="require('@/assets/tournament-logo.svg')" height="130px" />
                                </div>
                            </div>
                            <div class="card-participate my-5 w-100">
                                <ParticipateCard @init-pop-up="initPopUp" @view-bracket="viewBracket" :outlined="true">
                                </ParticipateCard>
                            </div>
                            <div
                                class="partners-container d-flex justify-content-center align-items-center px-2 px-lg-4 w-100">
                                <div class="logo-container">
                                    <img :src="require('@/assets/partners-logos.svg')" height="62px" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="infos-section pt-3 pt-lg-5">
                <div class="timeline-section container pb-3 pb-lg-5" id="timeline">
                    <TimeLine />
                </div>
                <div class="prizes-container container my-3 my-lg-5 p-lg-0 py-5 w-100">
                    <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                        <div class="position-relative p-0 m-0">
                            <h2 class="section-title text-left">Ljawa2iz</h2>
                        </div>
                    </div>
                </div>

                <div class="prize-container">
                    <PrizesCards />
                </div>
            </div>

            <!--  <div class="how-to-participate-container my-3 my-lg-5 p-lg-0 py-4 w-100">
                <div class="how-to-content d-flex position-relative  my-lg-4  m-0 w-100">
                    <div class="how-to-container container">
                        <div class="how-to-text-container pr-0 pr-lg-3 w-75">
                            <div
                                class="card-participate d-flex flex-column-reverse flex-lg-row justify-content-end align-items-center mr-4 w-100">
                                <ParticipateCard class="mr-lg-4" @init-pop-up="initPopUp" @view-bracket="viewBracket">
                                </ParticipateCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 -->
            <div class="bracket-container container my-4 my-lg-5 p-lg-0 py-4 w-100" id="bracket">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-2">
                    <div class="position-relative p-0 m-0">
                        <h2 class="section-title text-left">Bracket Dyal Tournoi</h2>
                    </div>
                </div>
                <div class="bracket d-flex justify-content-center align-items-center">
                    <BracketViewer></BracketViewer>
                </div>
            </div>

            <div class="stream-container container my-3 my-lg-5 p-lg-0 py-4 w-100" id="stream">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-2">
                    <div class="position-relative p-0 m-0">
                        <h2 class="section-title text-left">Stream</h2>
                    </div>
                </div>
                <div class="stream" ref="twitchPlayer"></div>
            </div>

            <div class="faq-container container my-3 my-lg-5 p-lg-0 py-4 w-100">
                <FaqList />
            </div>
            <!--<div class="krafton-copyright-footer-container d-flex justify-content-center align-items-center  p-5 w-100">
                <div
                    class="krafton-copyright-footer d-flex flex-column  justify-content-center align-items-center container w-100">
                    <div class="d-flex flex-column justify-content-center align-items-end  p-0 mb-5 w-100 w-lg-75">
                        <div class="my-2">
                            <h3 class="contact-title text-justify">Contactez nous</h3>
                            <p class="contact-text  text-justify">
                                En cas de questions supplémentaires, vous <br class="br" v-if="!isMobile">pouvez nous
                                contacter sur notre chaîne Discord.
                            </p>
                        </div>
                        <div class="my-2">
                            <iframe src="https://discord.com/widget?id=1134966955779039293&theme=dark" height="260"
                                allowtransparency="true" frameborder="0"
                                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
                            </iframe>
                        </div>
                    </div>

                </div>
            </div> -->
            <!-- Discord help banner-->
            <div class="container p-lg-0 w-100">
                <DiscordBanner />
            </div>
            <!-- Mastercard Footer-->
            <div class="w-100">
                <MasterCardFooter />
            </div>
            <!-- lgame footer -->
            <!--<LgameFooter></LgameFooter>-->

            <!-- Lgame participate pop up-->
            <LgameParticipatePopUp :pop-up-type="popUpOptions.type" :title="popUpOptions.title"
                :pop-up-message-body="popUpOptions.message" :with-options="popUpOptions.withOptions"
                :options="popUpOptions.options" v-if="showPopUp" @modal-closed="abortAction"
                @acceptAction="acceptAction" @abortAction="abortAction"></LgameParticipatePopUp>
        </div>
    </div>
</template>

<script>
    import BracketViewer from './BracketViewer.vue';
    import ParticipateCard from './ParticipateCard.vue';
   // import LgameFooter from './LgameFooter.vue';
    import LgameNavBar from './NavBar.vue';
    import LgameParticipatePopUp from './LgameParticipatePopUp.vue';
    import FaqList from './FAQ/FaqList.vue';
    import TimeLine from './TimeLine.vue';
    import PrizesCards from './Prizes/PrizesCards.vue';
    import MasterCardFooter from './MasterCardFooter.vue';
    import DiscordBanner from './DiscordBanner.vue';

    export default{
        name: "MainPage",
        components:{
            ParticipateCard,
            BracketViewer,
            LgameParticipatePopUp,
            LgameNavBar,
            //LgameFooter,
            FaqList,
            TimeLine,
            PrizesCards,
            MasterCardFooter,
            DiscordBanner,
        },
        data(){
            return{
                twitchPlayer: null,
                playerLoaded: false,

                showPopUp: false,
                popUpOptions: {
                    type: '',
                    title: '',
                    message: '',
                },
                isMobile: false,
            }
        },
        computed:{
            isLoaded: {
                get() {
                    return !!this.currentTournament;
                },
                set(val) {
                    return val;
                }
            },
            currentTournament: {
                get() {
                    return this.$store.state.currentTournament.tournamentItem ? this.$store.state.currentTournament.tournamentItem : null;
                }
            },
        },
        methods: {
            initPlayer(){
                // eslint-disable-next-line
                this.twitchPlayer = new Twitch.Player(this.$refs["twitchPlayer"], {
                    width: '100%',
                    height: '100%',
                    channel: 'geekmaroctv',
                });
                this.twitchPlayer.setVolume(0.5);
                this.playerLoaded = true
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ONLINE, () => {
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: true,
                    })*/
                });
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ENDED, () => {
                    
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: false,
                    })*/
                });   
            },
            acceptAction(payload) {
                this.showPopUp = false;
                
                if (payload && payload.hasCustomFields) {
                    if (payload.teamId) {
                        this.$store.dispatch('currentTournament/setParticipantTeam', {
                            teamId: payload.teamId,
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                    else {
                        this.$store.dispatch(this.popUpOptions.popUpDispatch, {
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                } else {
                    if (payload && payload.teamId) {
                        this.$store.dispatch('currentTournament/setParticipantTeam', {
                            teamId: payload.teamId
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                    else {
                        console.log('payload from pop up action ================= ')
                        console.log(payload)
                        console.log('payload from pop up action ================= ')
                        if (this.popUpOptions.dispatchData) {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch, this.popUpOptions.dispatchData)
                                .then(() => {
                                    this.$store.dispatch('currentTournament/triggerLoading', false);
                                });
                        } else {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch).then(() => {
                                this.$store.dispatch('currentTournament/triggerLoading', false);
                            });
                        }
                    }
                }
            },
            abortAction() {
                this.showPopUp = false;
                this.$store.dispatch('currentTournament/triggerLoading', false);
            },
            initPopUp(options) {
                this.popUpOptions = options;
                this.showPopUp = true;
            },
            viewBracket(){
                this.$refs.bracketTrigger.click();
            },
        },
        created(){
            this.$store.dispatch('currentUser/authCheck').then(response => {
                if (response.success) {
                    this.$store.dispatch('currentUser/setCurrentUser');
                }
            });
            this.$store.dispatch('currentTournament/getTournament', 144);
        },
        mounted() {
            console.log("token: ", process.env.VUE_APP_API_TOKEN)
            const resizeOps = () => {
                document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
                this.isMobile = window.innerWidth <= 768 ? true : false;
            };

            resizeOps();
            window.addEventListener("resize", resizeOps);

            this.$refs['twitchScript'].onload = () => {
                this.initPlayer();
            }
        }
    }

</script>

<style scoped>
.main-page-wrapper{
    background-color: var(--bg-color);
}
.prizes-header-ar{
    z-index:2;
}

.main-page-landing-area{
    min-height: calc(var(--vh, 1vh) * 100);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 56px
}
.how-to-container{
    z-index: 1;
}

.infos-section{
    background-image: url('@/assets/body-bg.webp');
    background-position: bottom right;
    background-size: 100% 100%;
}
.section-title{
    color: var(--main-color);
    font-family: "monument_extendedultrabold" !important;
    font-weight: 600;
    position: relative;
    z-index: 2;
    text-transform:uppercase;
}
.section-title::after {
    content: "/////////////////////////";
    font-family: "Roboto Mono";
    font-size: 18px;
    color: white;
    position: absolute;
    z-index: 1;
    position: absolute;
    bottom: -16px;
    left: 0;
}

.arabic-title{
    font-family: 'Tajawal', sans-serif !important;
    z-index:5;
    font-weight: 600;
}
.how-to-text, .how-to-text-ar{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #707070;
    font-size: 14pt;
}
.how-to-text-ar{
    font-family:"Tajawal" !important;
}

.how-to{
    color: var(--main-color);
    font-family: "Tajawal";
    font-weight: 600;
    text-decoration: underline;
    font-size: 18pt;
}
.stream{
    height: 860px;
}
.bracket{
    height: 80vh;
    background-color: #161514;
    overflow: hidden;
}

.prize-title{
    color: var(--main-color);
    text-transform: uppercase;
    font-family: 'monument_extendedultrabold', sans-serif;
    font-size: 16pt;
    line-height: 100%;
}
.prize-card{
    filter: drop-shadow(2px 2px 4px rgba(0, 0,0,0.1));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: white;
}


.krafton-copyright-footer-container{
    background-repeat: no-repeat;;
    background-image:url('@/assets/footer.webp') !important;
    background-size: cover;
    background-position: bottom right;
    
}
.krafton-copyright-footer{
    
    
}
.contact-title{
    color: var(--main-color);
    font-family: "Roboto", sans-serif;
    font-weight: 700;
}
.contact-text{
    color: #707070;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}
.contact-title-ar{
    color: var(--main-color);
    font-weight: 600;
    font-family: "Tajawal" !important;
}
.contact-text-ar{
    color:#707070;
    font-weight: 600;
    font-family: "Tajawal" !important;
}
@media screen and (max-width: 800px){
    
}

@media screen and (max-width: 768px) {
    .br{
        visibility: hidden;
    }
    .main-page-landing-area{
        margin-top: 58px;
        background-image: url('@/assets/banner-mob.webp') !important;
    }
    .main-page-landing-area{
    /*background-image:url('@/assets/hero-mobile-bg.png') !important;*/
    }
    .tournament-logo img{
        height: unset;
        width: 280px;
        content: url('@/assets/logo-mobile.png');

    }
    .partners-container img{
        height: 40px;
    }

    .section-title{
        font-size: 18pt;
    }
    .how-to-content{
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 80%;
    }
    .stream{
        width: 100% !important;
        height: 360px;
    }
    
}
</style>