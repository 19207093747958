<template>
    <div class="discord-banner-wrapper p-4 w-100">
        <div class="banner-content">
            <div class="d-flex align-items-start">
                <div class="p-2 mr-2">
                    <img :src="require('@/assets/discord-icon.svg')" height="32px" />
                </div>
                <div class="p-2">
                    <h5 class="help-title text-left m-0">Besoin d'aide ?</h5>
                    <p class="help-desc text-left">
                        En cas de questions supplémentaires, vous pouvez<br>
                        nous contacter sur notre chaîne Discord.
                    </p>
                    <div class="my-2">
                        <iframe src="https://discord.com/widget?id=1134966955779039293&theme=dark" height="240px"
                            width="100%" allowtransparency="true" frameborder="0"
                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name: "DiscordBanner",
}
</script>
<style>
    .discord-banner-wrapper{
        clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
        min-height: 380px;
        background-image: url("@/assets/contact-ds-bg.png");
        background-position: bottom right;
        background-size: 100% 100%;
        border: 1pt solid rgba(128, 128, 128, 0.325);
        
    }
    .help-title{
        color: white;
        font-family: "Roboto";
        font-weight: 600;
        text-transform: uppercase;
    }
    .help-desc{
        color: white;
        font-family: "Roboto";
        font-weight: 400;
    }
    @media screen  and (max-width: 768px){
        .discord-banner-wrapper{
            background-size: auto 100%;
            background-position: center center;
            
        }
    }
</style>